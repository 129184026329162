<template>
  <div w="full" min-h="full" relative bg="white">
    <div class="h-58 w-full flex flex-col" :style="{ backgroundColor: 'var(--tc-color-bg-index)' }">
      <BusinessBUserHeader />
      <div
        flex="~ 1 col items-center justify-center" text="center white xl"
        class="-pre-wrap px-6 pb-10 font-600 leading-tight"
        :style="{
          background: 'center center no-repeat',
          backgroundSize: 'cover',
          backgroundImage: `url(${bgImg})`,
        }"
      >
        <!-- <div class="flex-1 whitespace-pre-wrap text-xl font-600 leading-tight"> -->
        <div class="whitespace-pre-wrap" :style="{ color: 'var(--tc-color-title-index)' }">
          {{ pageTitle }}
        </div>
        <div v-if="subTitle" class="mt-3 whitespace-pre-wrap text-sm font-400" :style="{ color: 'var(--tc-color-subtitle-index)' }">
          {{ subTitle }}
        </div>
        <!-- </div> -->
      </div>
    </div>

    <div class="position-relative px-3.5 pt-4.5 -mt-12">
      <div class="rounded-xl bg-white px-4 py-1 shadow-[0_2px_8px_0px_rgba(122,122,122,0.25)]">
        <!-- 选择地址 -->
        <MobileSearchDestination class="my-4" page-type="home" />
        <!-- 选择日期 -->
        <MobileSelectDate page-type="home">
          <template #default="{ formatDate }">
            <div flex="~ items-center" p="x-3.5" h="13" b="1 solid #B3B3B3" rounded="2.5">
              <img class="w-7" src="~assets/icons/icon-calendar.png" />
              <div v-if="formatDate" class="text-base text-[#1B1B1B] font-500" m="l-3.5">
                {{ formatDate }}
              </div>
              <div v-else text="base #B1B1B1" m="l-3.5">
                {{ t('key.search.select.dates') }}
              </div>
            </div>
          </template>
        </MobileSelectDate>
        <div class="van-hairline--top bg-[#B3B3B3]"></div>
        <div class="van-hairline--top bg-[#B3B3B3]"></div>
        <div m="y-3">
          <VanButton :block="true" size="large" color="#000000" style="--van-button-radius: 10px;" @click="handleSearch">
            <div text="center lg white" font="600">
              {{ t('key.home.searchTicket') }}
            </div>
          </VanButton>
        </div>
      </div>
    </div>

    <!-- 合作品牌 -->
    <BusinessBPartnerBrands />
    <div class="van-hairline--top mt-8 bg-[#B3B3B3]"></div>
    <!-- 如何赚取 -->
    <BusinessBHowToEarn />

    <van-divider p="x-6" />

    <!-- APP下载链接 -->
    <div flex="~" p="x-6 b-4">
      <img w="15" h="15" rounded="1/4" src="~/assets/logo.png" />
      <div m="x-3" flex="1">
        <div text="sm #121212" font="500">
          {{ t('key.app.download.title') }}
        </div>
        <van-rate
          v-model="rate"
          readonly
          allow-half
          color="var(--tc-color-star)"
          void-color="#e3e3e3"
          size="14"
          gutter="1"
          void-icon="star"
        />
        <div text="xs #121212">
          {{ t('key.app.download.desc') }}
        </div>
      </div>
      <NuxtLink to="https://applink.azgotrip.com/ZRpn/y389rc22" external class="self-center">
        <van-button size="small" color="var(--tc-color-view-button)">
          <span p="x-1" text="sm white" font="500">
            {{ t('key.app.download.install') }}
          </span>
        </van-button>
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ogImageUrl from '~/assets/logo.png'

const { t } = useI18n()

useSeoMeta({
  title: t('key.attractions.index.title'),
  description: t('key.attractions.index.descrition'),
  ogImageUrl,
})

const { pageTitle, subTitle, bgImg } = usePartnerConfig()

const { trackSearchClickEvent } = useTrackHome()

const { handleHomeSearch } = useUseHomePopup(trackSearchClickEvent)

function handleSearch() {
  handleHomeSearch()
}

const rate = ref(4.6)
</script>
